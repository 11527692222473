.inventory-row {
    margin-left: 1.5em;
}
.product-box {
    margin-top: 1.5em;
    padding-bottom: 1em;
}
.inventory-product-header-row {
    margin-top: 0.5em;
    margin-left: 0.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
}
.inventory-buttons {
    margin-top: 1em;
}
.inventory-sum-row {
    font-size: 16px;
    margin-top: 0.5em;
}
.inventory-label {
    margin-left: 0em;
}
.inline-form-field {
    display: inline-block;
    padding-left: 0.5em;
    width: 50% !important;
}
.border-bottom-1
{
    border-bottom: 3px solid white !important;
}

/*.header-margin-bottom{
    margin-bottom: 4px;
}*/
.header-text{
    display: inline-flex;
}

.section-button {
    text-align: left;
    width: 90%;
    box-shadow: none !important;
    outline: none !important;
}

.card-header {
    position: relative;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

/* CARD-HEADER ANIMATIONS */
@media (prefers-reduced-motion:reduce) {
    .section-button {
        transition: none
    }
}

.section-button:not(.active-panel) {
    color: #0c63e4;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125)
}

    .section-button:not(.active-panel)::before {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236a6a6a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(-90deg);
    }

.section-button::before {
    transform: rotate(0deg);
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0;
    margin-top: 4px;
    margin-right: 10px;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    float: left;
}

.card-body {
    background: white;
}

.card-body-loaded {
    background: white;
    opacity: 1;
    height: 100%;
    transition: opacity 0.75s;
}

.card-body-loading {
    background: white;
    opacity: 0;
    height: 0;
}

.sub-header {
    margin-left: 28px;
    color: gray;
}

.plot-list-header {
    color: black;
    float: left;
    margin-top: 12px;
    margin-left: 12px;
}

.plot-list {
    color: black;
    margin-top: 12px;
    display: inline-block;
    padding-left: 15px;
}

.plot-plant {
    max-width: 80%;
}

.disabled-color {
    background: lightgray
}


.inline-form-field {
    display: inline-block;
    right: 20px;
    position: absolute;
    width: 55%;
}

.set-done {
    display: flex; 
    justify-content: center; 
    margin-top: 9px;
    margin-right: 1%;
    position: relative; 
    z-index: 1; 
    float: right;
    border-radius: 1.25rem;
}

.plot-operation-row {
    display: inline;
    width: calc(100% - (145px + 1%));
    /*min-width: 225px;*/
    padding-right: 5px;
    padding-left: 15px;
}

.plot-operation-row-done {
    display: inline;
    width: calc(100% - (45px + 1%));
    /*min-width: 225px;*/
    padding-right: 5px;
    padding-left: 15px;
}

.plot-memo-row {
    display: inline;
    width: calc(100% - (55px));
    /*min-width: 225px;*/
    padding-right: 5px;
    padding-left: 15px;
}

.memo-row-subtext {
    font-size: 15px;
    color: gray;
    width: calc(100% - 25px);
}

.operation-list-name {
    font-weight: 700;
}

.set-done-box {
}

.show-chosen-plots-accordion {
    margin-top: 15px;
}

/*pest and ferts in modal*/
.altInputStyle {
    display: inline-block;
    margin-left: 1px;
    max-width: 125px;
    padding-right: 5px;
}

.observation-unit-select{
    min-width: 115px;
}

.pesticide-selection-margin {
    padding-top: 10px;
}

.harvest-silo-modal .modal-content {
    border: 1px solid mediumseagreen;
}

.invalid-border {
    border: 2px solid #e36363 !important;
    border-radius: 6px;
    box-shadow: 0 0 8px #e36363;
}

@media (min-width:576px) {
    .fert-list-mark-desktop {
        display: inline-block !important;
    }

    .fert-list-mark-mobile {
        display: none !important;
    }

    .plot-operation-row {
        display: inline;
        width: calc(100% - (180px + 1%));
    }
}

@media (max-width:575px) {

    .fert-list-mark-desktop {
        display: none !important;
    }

    .fert-list-mark-mobile {
        display: inline-block !important;
    }

    .fert-list-name{
        max-width: 250px;
    }
}

/*harvest bale selections..*/
.btn-check:checked + .btn{
    z-index:0 !important;
}

.harvesting-unit-type-btn-group {
    display: block;
    margin-top: 1rem;
}

.harvesting-unit-btn-group {
    display: block;
}

.tank-mixture-icon{
    padding-left: 5px;
}

.tank-mixture-icon > svg {
    margin-bottom: 4px;
}

.coordinates-label {
    padding-left: 10px;
}

.mark-spin {
    width: 24px;
    height: 24px;
    float:right;
    margin-right: 5%;
    margin-top: 16px;
    z-index: 1;
}
.hidden {
    display: none !important;
    visibility: hidden !important;
}

.done-box {
    position: absolute;
    right: 5%;
}
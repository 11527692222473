.badge-pill {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 15px;
}

.badge-loading {
    font-size: 18px;
    vertical-align: middle;
    margin-left: 10px;
}

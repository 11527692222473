.btn-mmfi-info {
    background-color: #27b25f;
    border: none;
}

.btn-mmfi-danger {
    background-color: red;
    border: none;
}

.btn-mmfi-add-btn {
    background-color: black;
    border: none;
}

.btn-show-more {
    background: #27b25f;
    border: 0px;
}
.btn-show-options {
    background: #27b25f;
    border: 0px;
    margin-left: 75px;
}
.mobi-custom-switch {
    padding-left: 3em;
}
.mobi-custom-switch input {
    width: 3em !important;
}
.form-check-label {
    padding-top: 4px;
    padding-left: 10px;
}
.show-in-all-years-switch {
    padding-bottom: 8px;
}

td {
    border: 1px solid black;
    padding: 4px;
}

.card {
    background: linear-gradient(rgb(250,250,250), white);
    border: 0px !important;
}

.card-header {
    background-color: rgb(250,250,250);
    padding-left: 2px;
}

.highlighted-plot-name-text {
    font-size: 10pt;
    font-weight: bold;
}

.headerStyles {
    background: #D0D0D0;
    color: black;
    font-weight: 500;
    font-size: 112%;
    line-height: 2.5;
    min-width: 80px;
    padding-left: 10px;
    margin-left: -12px;
    margin-right: -12px;
}

.extra-info-accordion {

}

.extra-info-accordion-header {
    padding-left: 0;
}

.btn.active {
    /* Active buttons are visible through other elements without this */
    z-index: 0 !important;
}

.alert-box {
    margin: 0 !important;
    justify-content: space-between;
    display: flex;
}

.alert-icon {
    margin-right: 10px !important;
}

.alert-error {
    background-color: rgb(255,33,33) !important;
    box-shadow: 1px 1px 25px;
}

.alert-error div {
    color: white;
}

.alert-message {
    font-size: 18px;
    margin: auto;
    padding: 10px;
}

.alert-close {
    margin-left: auto !important;
    right: 1px;
}

.alert-timer {
    margin-left: -10px;
    margin-right: -10px;
    height: 4px;
}

.custom-alert {
    width: 95vw;
    background-color: white;
    align-items: center;
    border-radius: 0.3rem;
    box-shadow: 0 2px 10px;
    border: 1px solid rgba(0,0,0,.2);
}

.custom-alert-pesticideoperation {
    width: 95vw;
    background-color: white;
    align-items: center;
    border-radius: 0.3rem;
    box-shadow: 0 2px 10px;
    border: 8px solid #C71A1A;
}

.alert-message-info {
    font-size: 16px;
    margin: auto;
    padding: 10px;
}

.alert-close-btn {
    padding: 1px 6px 1px 6px !important;
}

.alert-close-pesticideoperation-btn {
    padding: 10px 6px 20px 5px !important;
}

/* Setting alert index in App.js doesn't work for some reason */
.alert-index {
    z-index: 1100 !important;
}

.alert-footer {
    margin: 0 !important;
}

.floatButton-text {
    font-size: 18px;
    padding-left: 5px;
}

.row-icon-left {
    color: gray;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 2rem;
    width: 40px;
    height: 40px;
    float: left;
    margin-top: 12px;
    justify-content: center;
    padding-top: 7px;
    padding-left: 0;
}

.row-icon-operation {
    background-color: #E5F5CC;
}

.row-icon-memo {
    background-color: #27b25f;
}

.memo-accordion-attachment-icon {
    top: 20px;
    right: 1px;
    position: absolute;
    float: right;
}

.row-icon-right {
    display: flex;
    justify-content: center; 
    margin-top: 8px;
    right: 2%;
    position: absolute;
    z-index: 1;
}

.row-subtext {
    font-size: 15px;
    color: gray;
}

.row-margin-bot {
    margin-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.mark-row-style {
    padding-bottom: 15px;
    margin-bottom: 1em;
}

.mark-row-header {
    padding-bottom: 3px;
    margin-bottom: 5px;
}

.mark-row-content {
    padding-top: 5px;
    padding-left: 25px;
}

.mark-product-row {
    padding-bottom: 15px;
}

.mark-product-field {
    margin-left: 15px;
}

.mark-product-row-header {
    margin-bottom: 5px;
}

.nutrient-calculation-icon {
    float: left;
    padding-top: 0;
    padding-right: 9px;
}

.add-note-icon {
    float: left;
    padding-top: 0;
    padding-right: 15px;
}

.active-panel {
    font-weight: bold;
    color: white !important;
    background: #27b25f;
}

.inactive-panel {
    font-weight: 500;
    background: #afafaf;
}

.center {
    display: block;
    margin: auto;
}

body {
    background-color: rgba(202, 219, 199);
    /*padding-right: 0 !important*/
}

.accordion {
/*    overflow: scroll;
    height: 86.9vh;*/
}
.accordion-button {
    font-size: 0.9rem;
}

.lastCard {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    z-index: 1;
}

.card h4 {
    display: inline-block;
}

.card h5 {
    display: inline-block;
}

.card h6 {
    display: inline;
}

.farm-year-span
{
    right: 1em;
    /*top: 2px;*/
    position:absolute;
    /*margin-right: 5px;*/
}

.farm-name-span {
    /*left: 5px;*/
    top: 2px;
    /*position: absolute;
    margin-left: 1.0rem;*/
}

.farm-info-header {
    width: calc(100% - 50px);
}

.modal-footer-right-align {
    text-align: right;
    display: block !important;
}

.footer-button-left{
    float:left;
}

.react-calendar__tile, .react-calendar__month-view__weekdays {
    color: black;
}

.mobi-calendar-body {
    padding: 1px;
    background-color: black;
    margin: auto;
}

.mobi-calendar-content .modal-content {
    margin: auto;
    width: auto !important;
}

.action-modal-max-width
{
    /*max-width: 330px;*/
}

.form-label {
    margin-top: 0.5rem;
}

.list-group {
    border-radius: 0;
}

.modal-content-hidden {
    display: none;
}

.box img {
    width: 100%;
    height: 100%;
}

.box1 img {
    object-fit: cover;
}

.show-extra-samples {
   /* margin-left: 7px;*/
    margin-right: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: 35px;
    align-content: center;
    justify-content: center;
    text-align: left;
    border-bottom: 1px solid lightgray;
}

.show-extra-headers-samples > div {
    font-size: 13px !important;
}

.nutrient-table-title {
    background-color: #27b25f;
    border-radius: 2px;
    padding: 7px;
    font-size: 17px;
    color: white;
    font-weight: bold;
}

.storage-row {
    padding-left: 1.5em;
}

.storage-header-row {
    font-size: 16px !important;
    border-bottom: 1px solid lightgray;
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.inventory-activation {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
}

.nutrient-header-row {
    font-size: 18px !important;
    border-bottom: 1px solid lightgray;
    padding-top: 5px;
}

.nutrient-table-header {
    padding-left: 15px;
    padding-right: 15px;
}

.nutrient-table {
    padding-left: 15px;
    padding-right: 15px;
}

.nutrient-data-row {
    font-size: 16px !important;
    border-bottom: 1px solid lightgray;
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: 35px;
    align-content: center;
}

.disabledButton {
    pointer-events: none;
}

.growth-stage {     
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: 35px;
    align-content: center;
    justify-content: left;  
}

.chart-zoom-select {
    margin-bottom: 1em;
}

.pro-plus-link {
    margin-left: 3px;
}
#settings-modal {
max-width: 400px !important;
}


#settings-modal .checkboxStyle {
    padding-bottom: 15px;
}

#settings-modal .checkboxStyle label{
    color: black;
    margin-left: 20px;
}

#settings-modal .logout {
    float: right;
}

#settings-modal .log-out-btn {
    background-color: transparent!important;
    border: 1px solid transparent !important;
}

.navbar {
    border-bottom: solid 1px #EBEBEB;
    padding-top: 5px !important;
    height: 65px;
}

.nav-mobiwisu {
    width: 16%;
}

.nav-col-center {
    width: none;
    text-align: center;
}

.nav-col-right {
    width: 21%;
    text-align: left;
}

.nav-col-left {
    width: 21%;
    text-align: right;
}

.bottom-tab-nav {
    border-top: solid 1px #c7e2bc;
    background-color: #c7e2bc;
}

.bottom-nav-link.active {
    color: blue;
}

.bottom-tab-label {
    font-size: 18px;
}

.nav-link {
    padding: 0.5rem 1rem !important;
}

.nav-text-green {
    font-weight: bold;
    color: #27b25f;
}

.mb-3, .my-3 {
    margin-bottom: 0px !important;
    background-color: white;
    margin-top: 10px;
}
.header-div {
    height: 67px;
}
.header-nav {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0;
}

.settings-style {
    position: absolute;
    right: 1px;
    top: 9px;
}

.nav-container-style {
    /*padding-left: 30px;*/
}

.container {
    justify-content: normal !important;
}

.header-logo {
    position: absolute;
    left: 20px;
    top: 5px;
    height: 60px;
}

/*for mobile*/ 
@media (max-width:576px) {
    .header-logo {
        height: 55px;
        left: 10px;
    }
/*
    .bottom-tab-label {
        font-size: 18px;
    }
*/
    .hide-mobile{
        display:none;
    }
}
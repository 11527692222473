
/*@media (min-width:576px) @media (min-width:768px) @media (min-width:992px) {*/

.customButton {
    width: 120px;
    height: 110px;
    letter-spacing: .5px;
    background-color: rgb(236, 243, 234);
    color: #fff;
    font-size: 32px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 5px 10px 5px;
    font-size: .9rem !important;
    vertical-align: top;
}

.homeMemoButton {
    width: calc(100% - 10px);
    height: 100%;
    letter-spacing: .5px;
    background-color: rgb(236, 243, 234);
    color: #fff;
    font-size: 32px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 5px 0px 5px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: .9rem !important;
    vertical-align: top;
}

.customButtonPlanned {
    width: 145px;
    height: 110px;
   /* letter-spacing: .5px;*/
    background-color: #FFE4B5;
    color: #fff;
    font-size: 32px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 5px 10px 5px;
    font-size: .9rem !important;
    vertical-align: top;
}

.memo-button-plot {
    color: gray;
    font-weight: 700;
}

.memo-attachment-icon {
    right: 30px;
    position: absolute;
    float: right;
}

#div-show-all-memos {
    margin-top: 10px;
}

@media (min-width:355px) {
    .customButton {
        width: 139px;
    }
}

@media (min-width:375px) {
    .customButton {
        width: 145px;
    }
}

@media (min-width:390px) {
    .customButton {
        width: 152px;
    }
}

@media (min-width:410px) {
    .customButton {
        width: 165px;
    }
    .customButtonPlanned {
        width: 165px;
    }
}

.button-div {
    display: inline-block;
    text-align: center;
    font-size: 12pt;
    width: 100%;
}

.button-notes-div {
    display: inline-block;
    text-align: left;
    font-size: 12pt;
    width: 100%;
}

.icon-and-text {
    /*margin-left: 5px;*/
    width: 95%;
    position: relative;
    display: inline-flex;
}

.forecast-button {
    height: 65px;
}

.align-left {
    text-align: left;
    position: relative;
    top: 10px;
}

.highlighted-green-number {
    color: rgb(39,178,95);
    font-size: 16pt;
    font-weight: bold;    
}

.storage-harvest-product {
    font-size: 11pt;
    color: gray;
    font-style: italic;
}

.medium-text {
    font-size: 11pt;
    font-weight: 500;
}

.highlighted-text {
    font-size: 13px;
    font-weight: bold;
}


.highlighted-text-samples {
    font-size: 11px;
    font-weight: bold;
}

.highlighted-green-date {
    color: rgb(39,178,95);
    font-size: 14pt;
    font-weight: bold;
}

.smaller-text {
    font-size: 11pt;
}

.smaller-text-notes {
    padding-top: 1px;
    color: black;
    font-size: 12px;
    font-weight: 400;
}

.smaller-text-notes-last {
    color: gray;
    padding-top: 5px;
    font-size: 12px;
}

.show-more-div {
    margin-left: 0.38em;
}

.show-extra-headers > div {
    font-size: 14px !important;
}

.show-extra-headers .col-1 {
    padding-left: 0;
    padding-right: 0;
    text-align: right;
}

.show-extra {
    margin-left: 7px;
    margin-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: 35px;
    align-content: center;
    justify-content: center;
    text-align: left;
    border-bottom: 1px solid lightgray;
}

.show-extra .col-1 {
    margin: auto;
}
.show-extra .col-2 {
    margin: auto;
}
.show-extra .col-3 {
    margin: auto;
}
.show-extra .col-5 {
    margin: auto;
}

.first-card-div {
    padding-top: 14px;
}

.no-plots-div{
    padding-left:10px;
}

.no-sample-sets-div {
    padding-left: 10px;
}

.error-div {
    padding-left: 20px;
}

.logout-before-btn {
    padding-right: 40px;
}

.growth-stage-list-arrow {
    margin-top: 30px;
}

.memo-list-arrow {
    margin-top: 16px;
}

.growthstage-card-row {
    display: inline-block;
    margin-left: 5px;
}

.growthstage-green-number {
    color: rgb(39,178,95);
    font-size: 16pt;
    font-weight: bold;
    text-align: left;
    align-content: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    max-width: 40px !important;
    display: inline-block;
}

.growthstage-card-text {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font-size: 14px;
    align-content: center;
    justify-content: center;
    margin-left: 7px;
    margin-bottom: 6px !important;
}

.growthstage-card-date {
    font-size: 16px;
    vertical-align: bottom;
}

.growth-stage-text {
}

.harvest-prognosis {
    font-style: italic;
}
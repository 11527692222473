.map-popup:has(.map-popup-content) {
    display: block;
}

.map-popup-content {
    padding: 5px;
    margin: auto;
    min-width: 200px;
    background-color: #f8f8f8;
    border: 1px solid #1242A3;
    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.2);
}

.weather-forecast-carousel {
    height: 12em;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1em;
    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.1);
}

/* Forecast */
.carousel-caption {
    padding: 0;
}

.forecast-radio-button {
    background-color: white;
    font-size: 0.8em;
    padding: 0.5em;
    color: #1242A3;
    width: 10%;
}

.forecast-wind-dir {
    margin-left: -3em;
    padding-left: 0;
}

.forecast-time {
    padding-top: 1em;
}

.weather-zoom-radio-button {
    background-color: white;
    color: #1242A3;
    padding: 0.5em;
}

.weather-zoom-group {
    
    margin-bottom: 0.5em;
}

.date-header {
    margin-bottom: 0;
}

.weather-forecast-date-select {
    margin-bottom: 1em;
}

.weather-forecast-day-row {
    padding-left: 1em;
}

.weather-forecast-row {
    text-align: center;
    margin-bottom: 0.3em;
    border-top: solid 1px lightgray;
    border-left: solid 1px lightgray;
    border-right: solid 1px lightgray;
    border-bottom: solid 2px #a9a9a9;
    
    font-size: 0.9em;
    border-radius: 0.5em;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
}

.weather-forecast-col {
    padding-left: 0;
    padding-right: 0;
}

.weather-station-selection {
    border-color: #1242A3;
    background-color: white;
    font-size: 0.9em;
    color: #1242A3;
    border-radius: 1.5em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    margin-top: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1.0em;
    height: 2.5em;
    width: 120px;
}

.station-map-icon {
    margin-top: -0.2em;
}

.weather-station-icon {
    margin-right: 0.3em;
}

.weather-type-icon {
    margin-bottom: 7px;
    margin-left: 15px;
}

.weather-button-label {
    color: gray;
    font-weight: 500;
}

.weather-button-value {
    font-weight: 700;
    font-size: 15pt !important;
    color: #1242A3;
    display: inline-block;
    padding-top: 0.3em;
    padding-left: 0.1em;
}

.weather-button-unit {
    font-weight: 500;
    color: gray;
    display: inline-block;
    padding-left: 0.5em;
    padding-top: 0.3em;
}

.weather-first-type {
    display: inline-block;
    text-align:left;
    margin-left: 1em;
    width: 10em;
}

.weather-second-type {
    display: inline-block;
    text-align: left;
    margin-left: 1em;
}

.weather-button-div {
    display: block !important;
    text-align: left !important;
    font-size: 11pt !important;
}

.weather-button {
    width: calc(100% - 10px);
    height: 100%;
    letter-spacing: .5px;
    background-color: #E5F4FF;
    color: #fff;
    font-size: 32px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px 5px 10px 5px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: .9rem !important;
    vertical-align: top;
}

@media (min-width:355px) {
    .weather-second-type {
        display: inline-block;
        text-align: left;
    }

    .weather-station-selection {
        border-color: #1242A3;
        background-color: white;
        font-size: 0.9em;
        color: #1242A3;
        border-radius: 1.5em;
        padding-left: 0.2em;
        padding-right: 0.2em;
        margin-top: 0.5em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        margin-bottom: 1.0em;
        height: 2.5em;
        width: 45%;
    }
}


@media (min-width: 445px) {
    .weather-station-selection {
        border-color: #1242A3;
        background-color: white;
        font-size: 0.9em;
        color: #1242A3;
        border-radius: 1.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-top: 0.5em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        margin-bottom: 1.0em;
        height: 2.5em;
        width: 180px;
    }

    .weather-first-type {
        display: inline-block;
        text-align: left;
        margin-left: 1.5em;
        width: 11em;
    }

    .forecast-radio-button {
        background-color: white;
        font-size: 0.9em;
        padding-left: 0.8em;
        padding-right: 0.8em;
        color: #1242A3;
        width: 15%;
    }
}

.weather-data-refresh-info {
    font-size: 0.9em;
    color: gray;
    margin-left: 1em;
}

.btn-weatherdata-show-more {
    margin-left: 5px;
}

.ol-zoom {
    top: 5.5em !important;
    left: 0.4em !important;
}

.modal-map .ol-zoom {
    top: 10px !important;
    left: 5px !important;
}

.map-satellite-top {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    right: 10px;
    top: 40px;
    border: 2px solid;
    border-radius: 3px;
}

.feature_filter-top {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    right: 10px;
    top: 100px;
    border: 1px solid;
    border-radius: 3px;
}

.feature_filter-font-normal {
    font-size: 11px;
    font-weight: bold;
}

.feature_filter-font-disabled {
    font-size: 11px;
    font-weight: normal;
    text-decoration: line-through;
}

.map-satellite-img {
    height: 45px;
}

.mapActionButtonStyle {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    /*opacity: 0.8;*/
    bottom: 40px;
}

.mapMarkAsStyle {
    bottom: 10px;
    right: 10px;
}

.mapAddModalStyle {
    bottom: 65px;
    right: 10px;
}

.mapInfoStyle {
    bottom: 120px;
    right: 10px;
}

.backMapButton {
    top: 40px;
    left: 9px;
}

.gpsMapButton {
    position: absolute;
    bottom: 50px;
    left: 10px;
}

.addFeatureMapButton {
    top: 200px;
    width: 150px !important;
}

.mapGreenButton {
    border-radius: 50px;
    position: absolute;
    width: 45px;
    height: 45px;
    float: right;
    display: flex;
    justify-content: center;
    opacity: 0.9;
    background: #198754;
    border: 1px solid mediumspringgreen;
}

    .mapGreenButton:disabled {
        background-color: #198754;
        border-color: #198754;
        pointer-events: none;
        opacity: .50;
    }

.greenBackground {
    opacity: 0.9;
    background: #198754;
}

.mapGreenButton > svg {
    margin-top: 5px;
}

.mapButtonStyle {
    border-radius: 50px;
    position: absolute;
    width: 35px;
    height: 35px;
    float: right;
    display: flex;
    justify-content: center;
    opacity: 0.8;
}

.farmNameMap {
    z-index: 1000;
    position: absolute;
    /*color: white;*/
    text-align: center;
    width: 100%;
}

.map-header-backg {
    background-color: rgba(255,255,255,0.8);
}

.mouse-position {
    bottom: 10px;
    position: absolute;
    right: 85px;
}

.vh-97-5 {
    height: calc(100% - 80px);
}

.map-default {
    height: 100%;
}

.map-height-transition {
    transition: height 0.25s;
}

.bottom-pane {
    position: absolute;
    bottom: 1px;
    width: 100%;
    background-color: white;
    border-top: 3px solid #50a82a;
    text-indent: 15px;
}

.bottom-pane-extended {
    height: 250px;
}

.map-action-button-group {
    position: fixed;
    right: 10px;
}

.gps-button-div {
    position: fixed;
    left: 5px;
}

#popover-basic-add > .header-font {
    font-size: 13px;
    padding: 7px;
    text-align: center;
}

.popover-body {
    padding: 9px;
}

    .popover-body label {
        font-size: 12px;
    }

.margin-bottom-10:focus {
    background-color: yellow;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

/* MAP-MODAL */
.modal-map {
    height: 67vh;
    width: 100%;
}

.feature_add_point-sample {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    right: 20px;
    top: 25px;
    z-index: 1;
}

.gps-modal-button-div {
    position: absolute;
    left: 15px;
    bottom: 10px;
    z-index: 1;
}

.map-modal-action-button-group {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 1;
}

.mapModalCollectAsStyle {
    bottom: 65px;
    right: 10px;
}

.mapModalDeleteStyle {
    bottom: 10px;
    right: 10px;
}

.mapModalGreenButton {
    border-radius: 50px;
    position: absolute;
    width: 45px;
    height: 45px;
    float: right;
    display: flex;
    justify-content: center;
    opacity: 0.9;
    background: #198754;
    border: 1px solid mediumspringgreen;
}

    .mapModalGreenButton:disabled {
        background-color: #198754;
        border-color: #198754;
        pointer-events: none;
        opacity: .50;
    }

    .mapModalGreenButton > svg {
        margin-top: -5px;
    }

.mapRedButton {
    border-radius: 50px;
    position: absolute;
    width: 45px;
    height: 45px;
    float: right;
    display: flex;
    justify-content: center;
    opacity: 0.9;
    background: #F44336;
    border: 1px solid mediumspringgreen;
}

    .mapRedButton:disabled {
        background-color: #F44336;
        border-color: #F44336;
        pointer-events: none;
        opacity: .50;
    }

    .mapRedButton > svg {
        margin-top: 8px;
    }

.mapGreenInvertedButton {
    border-radius: 50px;
    position: absolute;
    width: 45px;
    height: 45px;
    float: right;
    display: flex;
    justify-content: center;
    opacity: 0.9;
    background: white;
    border: 1px solid mediumspringgreen;
}

.mapGreenInvertedButton > svg {
    margin-top: -5px;
}

.show-selected-plots {
    font-size: 14px;
    /* margin-right: 15px;*/
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 50px;
    /*  align-content: center;*/
    /*    justify-content: center;*/
    text-align: left;
    border-bottom: 1px solid lightgray;
    margin-right: 0px;
}

.feature_add_point-top {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    right: 10px;
    top: 140px;
    border: 1px solid;
    border-radius: 3px;
}

.feature_delete_point-top {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    right: 0px;
    top: 160px;
    border: 1px solid;
    border-radius: 3px;
}

.selection-area-trim {
    /*overflow-y: scroll;*/
    overflow-y: auto;
    height: 120px;
    scrollbar-width: none;
}
.selection-area-trim::-webkit-scrollbar {    
    display: none;
}

/*.selection-area-trim::-webkit-scrollbar-thumb {
    background-color: #0064a7 #8ea5b5;
}*/

.scrollable-bottom {
    position: absolute;
    display: flex;   
    left: 50%;
    bottom: 1px;
    transform: rotate(90deg);
}

.highlighted-observation {
    font-weight: bold;
}
#start-pause, #task-amount, #stop {
    text-align: center; 
    display: inline-block;
    min-width: 33.33%;
}

#task-amount {
    background-color: #FCFCFC;
}

.task-amount-disabled {
    color: gray;
}

#stop {
    background-color: #f5C6CB;
}

.start {
    background-color: #28A745;
}

.pause {
    background-color: #FFD35C;
}

.map-header {
    margin-left: 0;
    margin-right: 0;
}

.fertilizing-header {
    background-color: #DBD6FF;
}

.seeding-header {
    background-color: #FFDB8F;
    text-align: right;
}

.fertilizing-inactive-header {
    background-color: #DBDBDB;
}

.seeding-inactive-header {
    background-color: #DBDBDB;
    text-align: right;
}

.spraying-header {
    background-color: #D2961E;
}

.tillage-header {
    background-color: #0A46A0;
}

.checkboxStyle {
    align-items: center;
    display: flex;
}

.mapButtonStyle {
    border-radius: 50px;
    width: 35px;
    height: 35px;
    float: right;
    display: flex;
    justify-content: center;
    opacity: 0.8;
}

.tractor-style {
    height: 30px;
    margin-top: 15px;
    border-radius: 2px;
    outline: none !important;
    transition: 0.3s;
    border-width: 1px;
    border-style: solid;
    box-shadow: inset 0 -2px 4px rgb(0 0 0 / 10%);
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

    .tractor-style svg {
        filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4)) !important;
    }

.tractor-selected {
    color: #fff;
    text-shadow: #000 0 1px 2px;
    background-color: #5eb13b;
    border-color: #469425;
}
.plotRowStyle  {
    /*align-items: left;*/ 
    display: flex;
}

.buttonStyle {
   /* margin: 10px;
    padding-bottom: 10px;
   */
    margin-right: 5px;
    margin-top: 4px;
}

/*.floatingButtonStyleVertical {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    padding-bottom: 10px;
    position: fixed;
    bottom: 12%;
    right: 5%;
    opacity: 0.8
}
*/
/*.floatingButtonStyleHorizontal {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    padding-bottom: 10px;
    position: fixed;
    bottom: 21%;
    right: 12%;
    opacity: 0.8
}
    */
.footer-alert-style {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    z-index: 2;
    /*display:none; TODO: T��!!*/
}

.footerStyle {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    text-align: center;
    z-index: 1;
    background-color: rgba(202, 219, 199);
}

.div-footer-buttons {
    bottom: 0;
    position: sticky;
    z-index: 3;
    max-width: 400px;
    text-align: center;
}

.plotItemStyle-not-active {
    
}

.plotItemStyle:hover {
    background: white;
}

.plotItemStyle {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid lightgray;
    margin-left: 3px;
    transition: 0.3s;
}

.plot-row-selected {
    background: rgb(229, 240, 225);
    transition: 0.3s;
}

.plot-row-selected:hover {
    background: rgb(229, 240, 225);
    transition: 0.3s;
}

.border-bottom {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid lightgray;
}

.list-group {
   border-top: 1px solid lightgray;
}

.first-card-div {
    padding-top: 12px;    
    padding-bottom: 3px;
}

.memoRowStyle {
    display: flex;
}

.memoItemStyle {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid lightgray;
    margin-left: 3px;
}

.sampleRowStyle {
    display: flex;
}

.sampleItemStyle {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid lightgray;
}

.sampleRow-checkbox {
    height: 30px;
    margin-top: 15px;
    padding: 1px 5px;
    font-size: 12px;
}

.search-input-group {
    display: inline-block;
    width: calc(100% - 155px);
}

.searchFieldStyle {
    margin-bottom: 10px;
    width: calc(100% - 45px) !important;
    display: inline-block;
    margin-left: 0px;
    border-right: none;
}

.search-icon-style {
    background-color: white;
    border-left: none;
    display: inline-block;
    height: 38px;
}

.selecti {
    z-index: 1000;
    margin-bottom: 10px;
    width: 140px;
    margin-left: 0px;
    margin-right: 15px;
    display: inline-block;
}

.selection-text {
    color: rgb(39,178,95);
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    /*background: #FFFFE0;*/
}

/*select group or plant to show in front page*/
.form-check-input:checked {
    color: #fff;
    background-color: #27b25f !important;
    border: 1px solid rgba(0,0,0,.25);
    width: 26px;
    height: 26px;
    margin-bottom: 2px;
}

.form-check-input {
    width: 26px !important;
    height: 26px !important;
    margin-bottom: 2px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeInAgain {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
}

.fade-in-again {
    opacity: 0;
    animation: fadeInAgain ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
}

.total-amount-text {
    color: rgb(39,178,95);
    font-size: 15px;
    font-weight: bold;      
    float: right;
}

@media (max-width:400px) {
    .searchIconStyle {
        display: none;
    }
}
@media (min-width:576px) {
    .selecti {
        width: 200px;
    }

    .searchFieldStyle {
        width: 200px;
    }
    .searchFieldStyle {
        width: calc(100% - 45px) !important;
    }

    .search-input-group {
        width: calc(100% - 215px);
    }
}

@media (min-width:768px) {
    .selecti {
        width: 250px;
    }
    .searchFieldStyle {
        width: 250px;
    }
    /* TODO: THESE!!! */
    .searchFieldStyle {
        width: calc(100% - 45px) !important;
    }
    .search-input-group {
        width: calc(100% - 265px);
    }
}